import GraduateOne from "../images/graduates/landingPage_March_OPEN-05.png";
import GraduateTwo from "../images/graduates/landingPage_March_OPEN-06.png";
import GraduateThree from "../images/graduates/landingPage_March_OPEN-07.png";
import GraduateThirty from "../images/graduates/landingPage_March_OPEN-30.png";
import GraduateThirtyOne from "../images/graduates/landingPage_March_OPEN-31.png";
import GraduateThirtyTwo from "../images/graduates/landingPage_March_OPEN-32.png";
import GraduateThirtyThree from "../images/graduates/landingPage_March_OPEN-33.png";
import GraduateThirtyFour from "../images/graduates/landingPage_March_OPEN-34.png";
import GraduateThirtyFive from "../images/graduates/landingPage_March_OPEN-35.png";
import GraduateThirtySix from "../images/graduates/landingPage_March_OPEN-36.png";

const graduatesData = [
  {
    imageUrl: GraduateOne,
    title: "אברהם יאסו ",
    miniTitle: "הנדסאי טכנולוגיות מים",
    details: " מחלקת הנדסה בתאגיד המים – מי נתניה",
  },
  {
    imageUrl: GraduateTwo,
    title: "יעל אביקר",
    miniTitle: "הנדסאי אדריכלות ועיצוב פנים",
    details: "בעלת משרד אדריכלות",
  },
  {
    imageUrl: GraduateThree,
    title: "אריאל בן אבו",
    miniTitle: "הנדסאי טכנולוגיות מים",
    details: "מפקח תשתיות מים וביוב בתאגיד מי שבע",
  },
  {
    imageUrl: GraduateThirty,
    title: "אדווה בר דאב",
    miniTitle: "הנדסאי עיצוב מדיה",
    details: "מעצבת חווית משתמש בחברת סטארטאפ",
  },
  {
    imageUrl: GraduateThirtyOne,
    title: "חן מיזיניסקי",
    miniTitle: "הנדסאי אדריכלות ועיצוב פנים",
    details: "בעלים ושותפה במשרד אדריכלות ועיצוב פנים ״רגן-מיזינסקי״",
  },
  {
    imageUrl: GraduateThirtyTwo,
    title: "נאור חורב",
    miniTitle: "הנדסה אזרחית – הנדסאי בניין",
    details: 'בעלים "נאור חברת בניה ופיתוח בע"מ',
  },
  {
    imageUrl: GraduateThirtyThree,
    title: "ארז הררי",
    miniTitle: "הנדסה אזרחית - הנדסאי בניין",
    details: 'מנכ"ל ובעלים "ארז הררי הנדסה וייעוץ בע"מ',
  },
  {
    imageUrl: GraduateThirtyFour,
    title: "חיים עזרן",
    miniTitle: "הנדסאי מכונות – מכטרוניקה ",
    details: "מתחזק מערכות רפואיות בבית חולים ברזילאי",
  },
  {
    imageUrl: GraduateThirtyFive,
    title: "מאור שלחון",
    miniTitle: "הנדסאי חשמל",
    details: "עובד רשת בחברת החשמל",
  },
  // {
  //   imageUrl: GraduateThirtySix,
  //   title: "עמית סער",
  //   miniTitle: "בוגרת תואר ראשון בניהול המשאב האנושי",
  //   details: "עובדת בצוות HR בחברת Morphisec",
  // },
];

export default graduatesData;
