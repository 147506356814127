import Card1 from "../images/landingPage_March_OPEN-26.png";
import Card2 from "../images/landingPage_March_OPEN-22.png";
import Card3 from "../images/landingPage_March_OPEN-09.png";
import Card4 from "../images/landingPage_March_OPEN-08.png";
import Card5 from "../images/landingPage_March_OPEN-10.png";
import Card6 from "../images/landingPage_March_OPEN-11.png";

const itemsData = [
  {
    key: 1,
    imageUrl: Card1,
    title: "מעורבות חברתית בקהילה",
    text: "ספיר משולבת בסביבתה והיא חלק בלתי נפרד מהקהילה. מאות הסטודנטים שלנו פועלים ב-35 פרויקטים חברתיים ונהנים ממלגות סיוע לצד תחושת סיפוק ושליחות.",
  },
  {
    key: 2,
    imageUrl: Card2,
    title: "חדשנות",
    text: "מרכז החדשנות בספיר מפעיל תכנית לימודים שנתית פורצת דרך, הכוללת מעבדות חדשנות ויזמות לפיתוח סטארטאפים.",
  },

  {
    key: 3,
    imageUrl: Card3,
    title: "קריירה ותעסוקה",
    text:
      "תקופת הלימודים בספיר מהווה חממה לפיתוח כישורים בעולמות הקריירה. " +
      "הלימודים כוללים התמחויות והתנסויות בתעשייה תחת תוכנית Top Job , הסטודנטים והסטודנטיות יוצרים רשת קשרים ונקודת פתיחה משמעותית לתעסוקה איכותית, מספקת ומתגמלת בחברות מובילות במשק הישראלי.",
  },
  {
    key: 4,
    imageUrl: Card4,
    title: "ליווי אישי",
    text: "למידה בקבוצות קטנות ואיכותיות לצד תרגולים ותגבורים! בספיר נלווה אותך מהרגע הראשון ועד ליציאה לשוק העבודה.",
  },
  {
    key: 5,
    imageUrl: Card5,
    title: "שכר לימוד ומלגות",
    text: "המכללה הטכנולוגית ספיר מהמובילות בארץ. קיים מימון שכר לימוד בסיסי בגובה של עד 90% לשנתיים לצד מגוון רחב של מלגות סיוע.",
  },

  {
    key: 6,
    imageUrl: Card6,
    title: "אווירה סטודנטיאלית",
    text: "הסטודנטים של ספיר מגיעים מכל קצוות הארץ ויוצרים אווירה סטודנטיאלית ייחודית, נהנים ממדשאות ירוקות, ממעבדות ואולפנים חדשניים ומחיי קהילה תוססים במעונות שבקרבת הקמפוס.",
  },
];

export default itemsData;
