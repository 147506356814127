// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageWithTitleContainer {
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 60vh;
  min-width: 24%;
  max-width: 24%;
  margin: 0 auto;
  margin-top: -8%;
}

.textContainer {
  color: #000;
  width: 100%;
  margin-top: auto; 
  text-align: right;
  line-height: 1;
}

.title {
  font-family: 'ALMONI-BOLD-AAA';
  margin: 0 auto;
  padding: 0;  
  
}

.miniTitle {
  font-family:"ALMONI-REGULAR-AAA" ;
  margin: 0 auto;
  margin: 0 auto;
  padding: 0;
}

.details {
  margin: 0 auto;
  padding: 0;
  font-family:"ALMONI-LIGHT-AAA" ;
}


@media (max-width: 768px) {

  .imageWithTitleContainer {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 70%;
    max-width: 70%;
    
  }
  .textContainer {
    color: #000;
    width: 100%;
    margin-top: auto; 
    text-align: right;
    line-height: 1.2;
    font-size: larger;
  }
  


}`, "",{"version":3,"sources":["webpack://./src/component/Graduates.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,YAAY;EACZ,cAAc;EACd,cAAc;EACd,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,8BAA8B;EAC9B,cAAc;EACd,UAAU;;AAEZ;;AAEA;EACE,iCAAiC;EACjC,cAAc;EACd,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,UAAU;EACV,+BAA+B;AACjC;;;AAGA;;EAEE;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,sBAAsB;IACtB,4BAA4B;IAC5B,2BAA2B;IAC3B,cAAc;IACd,cAAc;;EAEhB;EACA;IACE,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;EACnB;;;;AAIF","sourcesContent":[".imageWithTitleContainer {\r\n  display: flex;\r\n  flex-direction: column; \r\n  align-items: flex-start; \r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n  height: 60vh;\r\n  min-width: 24%;\r\n  max-width: 24%;\r\n  margin: 0 auto;\r\n  margin-top: -8%;\r\n}\r\n\r\n.textContainer {\r\n  color: #000;\r\n  width: 100%;\r\n  margin-top: auto; \r\n  text-align: right;\r\n  line-height: 1;\r\n}\r\n\r\n.title {\r\n  font-family: 'ALMONI-BOLD-AAA';\r\n  margin: 0 auto;\r\n  padding: 0;  \r\n  \r\n}\r\n\r\n.miniTitle {\r\n  font-family:\"ALMONI-REGULAR-AAA\" ;\r\n  margin: 0 auto;\r\n  margin: 0 auto;\r\n  padding: 0;\r\n}\r\n\r\n.details {\r\n  margin: 0 auto;\r\n  padding: 0;\r\n  font-family:\"ALMONI-LIGHT-AAA\" ;\r\n}\r\n\r\n\r\n@media (max-width: 768px) {\r\n\r\n  .imageWithTitleContainer {\r\n    display: flex;\r\n    flex-direction: column; \r\n    align-items: flex-start; \r\n    background-size: cover;\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n    min-width: 70%;\r\n    max-width: 70%;\r\n    \r\n  }\r\n  .textContainer {\r\n    color: #000;\r\n    width: 100%;\r\n    margin-top: auto; \r\n    text-align: right;\r\n    line-height: 1.2;\r\n    font-size: larger;\r\n  }\r\n  \r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
