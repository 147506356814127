import React from "react";
import "./Graduates.css";

function Graduates({ imageUrl, title, miniTitle, details }) {
  const containerStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <div className="imageWithTitleContainer" style={containerStyle}>
      <div className="textContainer">
        <h2 className="title">{title}</h2>
        <h3 className="miniTitle" role="presentation">{miniTitle}</h3>
        <p className="details">{details}</p>
      </div>
    </div>
  );
}

export default Graduates;
