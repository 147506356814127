const degreeOptions = {
  none: [],
  bachelor: [
    { value: "5005", label: "אמנויות הקול והמסך" },
    { value: "5093", label: "כלכלה וחשבונאות" },
    { value: "5010", label: "כלכלה יישומית וניהול" },
    { value: "5002", label: "לוגיסטיקה" },
    { value: "5092", label: "לימודים רב תחומיים" },
    { value: "5000", label: "מדעי המחשב" },
    { value: "5009", label: "מנהל ומדיניות ציבורית" },
    { value: "5016", label: "משפטים" },
    { value: "5004", label: "ניהול תעשייתי" },
    { value: "5001", label: "ניהול המשאב האנושי" },
    { value: "5003", label: "עבודה סוציאלית" },
    { value: "5006", label: "שיווק טכנולוגי" },
    { value: "5008", label: "תקשורת" },
    { value: "5007", label: "תרבות - יצירה והפקה" },
    { value: "5012", label: "הנדסה שנה א'" },
    { value: "5206", label: "דו-חוגי במנהל ומדיניות ציבורית ותקשורת" },
    { value: "5017", label: "עוד לא החלטתי" },
  ],
  master: [
    { value: "5013", label: "מנהל ומדיניות ציבורית" },
    { value: "5373", label: "מנהל ומדיניות ציבורית עם התמחות בחינוך" },
    { value: "5015", label: "קולנוע - הפקה ותאוריה" },
    { value: "5014", label: "ניהול ופיתוח המשאב האנושי" },
    { value: "5102", label: "עבודה סוציאלית" },
    { value: "5274", label: "תכנית השלמה לתואר שני בעבודה סוציאלית" },
    { value: "5103", label: "לימודי תרבות" },
    { value: "5201", label: "תקשורת וניו מדיה" },
    { value: "5271", label: "טיפול באומנויות - אמנות חזותית*" },
    { value: "5288", label: "חדשנות ויזמות בארגונים*" },
    { value: "5017", label: "עוד לא החלטתי" },
  ],
  practicalEngineer: [
    { value: "5104", label: "הנדסאי אדריכלות ועיצוב פנים" },
    { value: "5105", label: "הנדסאי ביוטכנולוגיה" },
    { value: "5106", label: "הנדסה אזרחית - הנדסאי בניין" },
    { value: "5107", label: "הנדסאי חשמל" },
    { value: "5108", label: "הנדסאי טכנולוגיות מים" },
    { value: "5109", label: "הנדסאי מכונות - מכטרוניקה" },
    { value: "5110", label: "הנדסאי מכונות בהתמחות רכב חשמלי" },
    { value: "5111", label: "הנדסאי עיצוב מדיה" },
    { value: "5112", label: "הנדסאי סאונד (קול)" },
    { value: "5247", label: "הנדסה רפואית - מכשור רפואי" },
    { value: "5114", label: "מכינה טכנולוגית נתיב להנדסאים" },
    { value: "5335", label: "טכנאי סאונד" },
    { value: "5017", label: "עוד לא החלטתי" },
  ],
  beforeAcademic: [
    { value: "5132", label: "מכינה 30+" },
    { value: "5133", label: "מכינה למדעי הרוח והחברה" },
    { value: "5134", label: "השלמת 12 שנות לימוד" },
    { value: "5017", label: "שיחת ייעוץ" },
  ],
};

export default degreeOptions;
