import React, { useState } from "react";
import "./LeavingDetails.css";
import SendButton from "../images/landingPage_March_OPEN-14.png";
import degreeOptions from "../data/degreeOptions";
import ThankPage from "../pages/ThankPage";

function LeavingDetails() {
  const [selectedDegree, setSelectedDegree] = useState("practicalEngineer");
  const [selectedDegreeOption, setSelectedDegreeOption] = useState("");
  const [showThankPageSelect, setShowThankPageSelect] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleDegreeChange = (event) => {
    setSelectedDegree(event.target.value);
    setSelectedDegreeOption("");
  };

  const handleDegreeOptionChange = (event) => {
    setSelectedDegreeOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const firstNameInput = document.getElementById("firstName");
    const lastNameInput = document.getElementById("lastName");
    const emailInput = document.getElementById("mail");
    const phoneInput = document.getElementById("phone");
    const degreeInput = document.getElementById("degreeOption");

    if (
      !firstNameInput.checkValidity() ||
      !lastNameInput.checkValidity() ||
      !emailInput.checkValidity() ||
      !phoneInput.checkValidity() ||
      degreeInput.value === 1
    ) {
      alert("Please fill in all the required fields.");
      return;
    } else {
      setIsButtonDisabled(true);

      const firstName = firstNameInput.value;
      const lastName = lastNameInput.value;
      const email = emailInput.value;
      const phone = phoneInput.value;
      // const selectedOptionDegree = degree.options[degree.selectedIndex];
      // const interested = selectedOptionDegree.textContent;
      const department = degreeInput.value;
      const selectedOption = degreeInput.options[degreeInput.selectedIndex];
      const departmentLabel = selectedOption.textContent;
      const username = process.env.REACT_APP_USERNAME;
      const password = process.env.REACT_APP_PASSWORD;
      const sourceId = process.env.REACT_APP_SOURCE_ID;
      const queryParams = new URLSearchParams(window.location.search);
      const utmSource = queryParams.get("utm_source");
      const utmMedium = queryParams.get("utm_medium");
      const utmCampaign = queryParams.get("utm_campaign");

      const jsonData = {
        data: {
          FIRSTNAME: firstName,
          LASTNAME: lastName,
          PHONE: phone,
          EMAIL: email,
          DEPARTMENT: department,
          // INTERESTED: interested,
          DEPARTMENT_NAME: departmentLabel,
          username: username,
          password: password,
          SOURCE_ID: sourceId,
          utmSource: utmSource,
          utmMedium: utmMedium,
          utmCampaign: utmCampaign,
        },
      };

      const raw = JSON.stringify({
        jsonData,
      });

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://openday.sapir.ac.il/api/process_request", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          window.location.href += "thankPage";
        })

        .catch(
          (error) => console.error(error),
          (window.location.href += "thankPage")
        );
    }
  };

  const closeThankPage = () => {
    setShowThankPageSelect(false);
  };

  return (
    <div>
      <form className="leaving-details-box" onSubmit={handleSubmit}>
        <h2>
          <span className="almoni-bold">לתיאום ייעוץ אישי </span>
          <span className="almoni-light">השאירו פרטים</span>
        </h2>
        <div className="input-group">
          <div className="input-group">
            <input
              type="text"
              id="firstName"
              placeholder=" השם הפרטי שלי *"
              aria-label="my first name"
              required
            />
            <input
              type="text"
              id="lastName"
              aria-label="my last name"
              placeholder="שם המשפחה שלי *"
              required
            />
          </div>
          <input
            dir="rtl"
            type="tel"
            id="phone"
            placeholder="הנייד שלי *"
            aria-label="my phone number"
            required
          />
        </div>
        <div className="input-group">
          <input
            type="email"
            id="mail"
            placeholder="המייל שלי *"
            aria-label=" my email adress"
            required
          />
          <select
            id="degreeOption"
            aria-label="my field of interest"
            onChange={handleDegreeOptionChange}
            value={selectedDegreeOption}
            required
          >
            <option value="">תחום העניין שלי *</option>
            {degreeOptions[selectedDegree].map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <button
            type="submit"
            id="send-button-button"
            disabled={isButtonDisabled}
          >
            <img
              id="send-button"
              alt="send-button"
              src={SendButton}
              type="submit"
            ></img>
          </button>
        </div>
        <h5 className="milga-text">
          *המימון מותנה בקבלת התקציב בפועל ובכפוף לתקנון שכ"ל
        </h5>
      </form>
      {showThankPageSelect && <ThankPage onClose={closeThankPage} />}
    </div>
  );
}

export default LeavingDetails;
