import React, { useState, useEffect } from "react";
import "../App.css";
import Header from "../component/Header";
import Gallery from "../component/Gallery";
import ImageWithTitle from "../component/ImageWithTitle";
import GraduatesRow from "../component/GraduatesRow";
import graduatesData from "../data/graduatesData";
import LastDetailsBox from "../component/LastDetailsBox";
import UnderImage from "../images/landingPage_March_OPEN-28.png";
import UnderImageMobile from "../images/landingPage_March_OPEN-30.png";
import itemsData from "../data/itemsData";
import Contact from "../images/landingPage_March_OPEN-25.png";

function HomePage() {
  const [isMobile, setIsMobile] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const startStice = 700;
    const endStick = 1550;

    setIsSticky(window.scrollY >= startStice && window.scrollY <= endStick);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const items = itemsData.map((item) => (
    <ImageWithTitle
      key={item.key}
      imageUrl={item.imageUrl}
      title={item.title}
      text={item.text}
    />
  ));

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="App" dir="rtl">
      <Header></Header>
      <br></br>

      <Gallery items={items} />
      <br></br>
      <br></br>
      {isMobile && (
        <div
          className={`fixed-image ${isSticky ? "sticky" : ""}`}
          onClick={handleScrollToBottom}
        >
          <img
            src={Contact}
            className="image"
            alt="Contact Page Icon: For more information and advice"
          />
        </div>
      )}
      <GraduatesRow graduatesData={graduatesData} />
      <br></br>
      <LastDetailsBox></LastDetailsBox>
      <br></br>
      <img
        className="under-image"
        alt="the college logo with a benefit for regisrants in March"
        src={!isMobile ? UnderImage : UnderImageMobile}
      ></img>
      <a href="https://www.sapir.ac.il/node/268">הצהרת נגישות</a>
      <br></br>
      <br></br>
    </div>
  );
}

export default HomePage;
